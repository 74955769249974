.ul
  display: flex
  justify-content: space-around

  .li
    position: relative
    text-align: center
    font-size: 0.8rem
    list-style-type: none

    .dot
      display: flex
      justify-content: center
      align-items: center
      margin-left: auto
      margin-right: auto
      width: 3rem
      height: 3rem
      border-radius: 100%


      &.green
        background-color: #99BC85

      &.red
        background-color: #FF6969

    .after
      &.red
        color: #FCAEAE
      &.green
        color: #D4E7C5
      &::after
        content: '....'
        position: absolute
        top: -25px
        right: -62px
        font-size: 3rem

.modal-container
    padding: 0


.modal-header
    background-color: #f8f9fa
    border-bottom: 1px solid #dee2e6


.grid-container
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 20px
    padding: 20px
    align-items: start


.column
    display: flex
    flex-direction: column
    align-items: center


.info-header
    text-align: center
    height: 150px
    display: flex
    flex-direction: column
    justify-content: center
    margin-bottom: 20px
    font-size: 1rem


.image-container
    display: flex
    flex-direction: column
    align-items: center
    margin: 10px 0
    text-align: center
    width: 100%
    max-width: 500px

.avatar
    width: 100%
    max-width: 150px
    height: auto
    margin-bottom: 10px

.document
    width: 100%
    max-height: 500px
    object-fit: cover


















