@import "./../../basedata/variables"

.user-gates-list
  list-style: none
  max-width: 60px
  display: flex
  flex-wrap: wrap
  max-width: 150px
  padding: 0
  li
    display: inline-block

.user-trigger
  border-radius: 6px
  padding: 16px 8px
  background-color: $pm-primary
  margin-left: 16px

.user-delete
  border-radius: 6px
  padding: 16px 8px
  background-color: $red-500
  margin-left: 16px

  &.disabled-button 
    background-color: #d3d3d3 
    color: #808080 
    cursor: not-allowed 
  

  +fontDefault
  text-decoration: none
  text-transform: uppercase

  &.user-not-found
    background-color: $red-500

.card .card-header h5.search-header
  margin-bottom: 16px

.btn-ghost
  font-size: 12px
  display: inline-block
  width: 100%
  background-color: lighten($pm-primary, 75%)
  border: 1px solid
  color: $pm-primary
  text-align: center
  margin-left: 0
  padding: 8px

.btn-ghost-delete
  font-size: 12px
  display: inline-block
  width: 100%
  background-color: lighten($red, 75%)
  border: 1px solid
  color: $red-500
  text-align: center
  margin-left: 0
  padding: 8px

  &:hover
    color: $pm-dark
    opacity: 0.3

.odd-trigger
  background-color: lighten($pm-primary, 65%)

.table th, .table td
  border: 0
  padding-bottom: 24px

.label-title
  +fontSubTitle
  font-size: 14px
  color: $pm-dark

  .status-danger
    color: $red-500
  .status-done
    color: $green-500

td.access-list
  // background-color: $gray-100 !important
  font-size: 12px
  border-bottom: 2px solid $pm-dark
  position: relative

  .accordion-button
    padding: 0
    font-size: 12px

.accordion-button
  color: $pm-primary !important

  &::after
    background-size: 12px !important
    color: $pm-primary !important

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed
  background-color: inherit

.span-sm-iput
  margin-top: 16px
  span
    display: inline
  form
    display: inline
  input, button
    width: auto
    display: inline
    padding: 3px 4px
    height: auto
    font-size: 12px
    max-width: 20%
    margin-left: 8px

  button
    font-size: 10px
    text-transform: uppercase
    padding: 4px
    height: auto

.modal-entry-denied
  color: $red !important
